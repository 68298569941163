<template>
  <!--已生成官网-->
  <div>
    <!--头部-->
    <div class="top dif alc" style="width: 1200px;margin: 0 auto;height: 67px;">
      <!--<img src="@/assets/image/yizhibang/16left_logo.png" style="width: 136px;height: 49px;">-->
      <img :src="info.log_image" style="width: 136px;height: 49px;">
      <div style="margin-left: 27px;">
        <p class="fs20" style="color:#888888">{{info.enterprise}}</p>
        <p class="fs16" style="color:#888888">{{href}}</p>
      </div>
    </div>
    <!--nav-->
    <div class="nav_warp mrTop10" style="height: 36px;" :style="{'background': info.top_color}">
      <div class="nav">
        <ul class="difa alc colfff fs18 h100">
          <li v-for="(item,index) in nav" :key="index" class="pointer fs14 colfff"
              :class="isActive==item.path?'active':''"  @click="goPage(item)" v-if="item.show==1">
            {{item.name}}
          </li>
        </ul>
      </div>
    </div>
    <!--  banner  -->
    <div class="banner mr0At" style="width: 100%;height: 500px;background: #5278f5">
      <el-carousel indicator-position="outside" height="500px">
        <el-carousel-item v-for="(item,index) in swiper" :key="item">
          <img :src="$store.state.url+item" class="w100 h100">
        </el-carousel-item>
      </el-carousel>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "createdWeb",
    data() {
      return {
        nav: [
          {id:0,name:'首  页',path:'/Web',show: 0},
          {id:2,name:'实时资讯',path:'/Web/news',show: 0},
          {id:1,name:'公司简介',path:'/Web/jianjie',show: 0},
          {id:3,name:'内部需求',path:'/Web/demand',show: 0},
          {id:4,name:'产品店铺',path:'/Web/shop',show: 0},
          {id:5,name:'品牌加盟',path:'/Web/join',show: 0},
          {id:6,name:'招聘菜单',path:'/Web/zhaopin',show: 0},
          {id:7,name:'软件下载',path:'/Web/download',show: 0},
          {id:8,name:'联系我们',path:'/Web/contactus',show: 1},
        ],
        id: '',//生成的官网id
        sid: '',//发布官网id
        href: '',
        isActive: '/Web',
        info: { //官网信息
          log_image: '', //logo图片
          top_color: '', //导航栏颜色
          enterprise: '',//企业名
        },
        swiper: [],//轮播图
      }
    },
    methods:{
      goPage(item){ //页面跳转
        this.isActive = item.path
        this.$router.push({
          path: item.path,
          query: {
            id: this.id,
            sid: this.sid,
          }
        })
      },
      getWebDetail() { //获取官网信息
        this.id = this.$route.query.id
        this.sid = this.$route.query.sid
        this.$axios("POST","/api/Release/getBindInfo",{id: this.sid})
        .then(res => {
          if(res.code===1) {
            this.info = res.data
            this.swiper = res.data.images.split(',')
            if(res.data.is_info==1) this.nav[2].show=1
            if(res.data.is_message==1) this.nav[1].show=1
            if(res.data.is_demand==1) this.nav[3].show=1
            if(res.data.is_store==1) this.nav[4].show=1
            if(res.data.is_league==1) this.nav[5].show=1
            if(res.data.is_recruit==1) this.nav[6].show=1
            if(res.data.is_app==1) this.nav[7].show=1
          }
        })
      },
    },
    created() {
      this.getWebDetail()
      this.href = window.location.href
    }
  }
</script>

<style scoped>
  /*头部*/
  .top{
    overflow: hidden;
    text-align: left;
    width: 1200px;
    margin: 0 auto;
  }
  /*nav*/
  .nav_warp{
    background: #3765FF;
  }
  .nav{
    width: 1200px;
    margin: 0 auto;
    height: 100%;
  }
  .nav li{
    height: 100%;
    flex: 1;
    padding: 6px 0;
  }
  .nav li.active{
    /*background: #194FFF;*/
    font-weight: bold;
    font-size: 16px;
  }
</style>