<template>
  <div class="con">
    <!--上方主题内容-->
    <div class="waveWrapper waveAnimation bg">
      <!--波浪效果-->
      <img src="@/assets/image/xiala.png" class="kv_more" alt="" />
      <div class="waveWrapperInner bgTop">
        <div
          class="wave waveTop"
          style="background-image: url('/images/wave-top.png')"
        ></div>
      </div>
      <div class="waveWrapperInner bgMiddle">
        <div
          class="wave waveMiddle"
          style="background-image: url('/images/wave-mid.png')"
        ></div>
      </div>
      <div class="waveWrapperInner bgBottom">
        <div
          class="wave waveBottom"
          style="background-image: url('/images/wave-bot.png')"
        ></div>
      </div>
      <!-- 头部 -->
      <headers
        @goIndustry="goIndustry"
        class="posr"
        style="z-index: 22"
      ></headers>
      <!-- 标题 -->
      <div class="title difcac posr" style="z-index: 21">
        <div style="width: 195px;height: 66px;">
          <img src="@/assets/image/yizhibang/home_logo.png" class="w100 h100" />
        </div>
      </div>

      <!-- 导航 -->
      <div class="nav posr" style="z-index: 21">
        <div
          v-for="item in classity"
          :key="item.id"
          class="pointer fs14 colE0E0E0"
          @click="setItem(item.id)"
        >
          <div :class="itemIf(item)">{{ item.name }}</div>
          <!-- <div :class="itemIf(item)?'active':'bb'" :key="item.id">{{item.name}}</div> -->
        </div>
      </div>
      <!-- 搜索框 -->
      <div class="search posr dif alc posr" style="z-index: 21;">
        <div
          class="fs24 col3A53FF bold dif alc pdRit30 posr"
          @mouseenter="showSearch = true"
          style="margin-left: 28px;border-right: 2px solid #00AEFF;"
        >
          <div class="pointer" style="width: 60px;" v-if="search !== '亿搜'">
            {{ search }}
          </div>
          <div class="pointer" style="width: 60px;" v-else>
            <img
              src="@/assets/image/yizhibang/home_logo.png"
              style="width: 62px;height: auto"
            />
          </div>
          <i
            class="el-icon-arrow-down pointer"
            style="color:#3A53FF;font-weight:bold;margin-left: 15px;"
          ></i>
          <div
            class="search_list pdTop20 difb difw"
            @mouseleave="showSearch = false"
            v-if="showSearch"
          >
            <div
              v-for="(item, index) in searchList"
              v-if="search !== item"
              @click="search = item"
              class="search_item pointer difcac"
            >
              <span v-if="item !== '亿搜'">{{ item }}</span>
              <img
                v-else
                src="@/assets/image/yizhibang/home_logo.png"
                style="width: 62px;height: auto"
              />
            </div>
          </div>
        </div>
        <input
          ref="searchInput"
          @keydown.enter="goSearch()"
          class="sea_left"
          type="text"
          v-model="keyword"
          @focus="
            showHistory = true;
            showSearch = false;
          "
          @blur="hideHistory"
          :placeholder="placeholder"
        />
        <!--搜索历史和相关搜素-->
        <div class="history" v-if="showHistory && $store.state.token">
          <!--相关-->
          <!-- <div style="width: 50%;">
            <div class="colccc fs14 tlt">搜索相关</div>
            <div class="history_item one_overflow pointer">亿指榜为人民指航</div>
            <div class="history_item one_overflow pointer">济南慧族软件科技有限公司</div>
            <div class="history_item one_overflow pointer">管家婆财务软件</div>
            <div class="history_item one_overflow pointer">济南管家婆</div>
          </div>-->
          <!--历史-->
          <div style="width: 100%;">
            <div class="colccc fs14 tlt">搜索历史</div>
            <div class="dif difw">
              <div
                class="history_item one_overflow pointer"
                style="width: 50%;"
                @click.stop="goSearch(item.info)"
                v-for="(item, index) in userSearchHistory"
                :key="index"
                v-if="index < 15 && item.info !== 'undefined' && item.info"
              >
                {{ item.info }}
              </div>
            </div>
          </div>
        </div>
        <div class="sea_right pointer" @click="goSearch()">立即搜索</div>
      </div>
    </div>

    <!-- 下方新闻  热门推荐 -->
    <div class="news posr">
      <!-- 左侧 -->
      <div class="news_left">
        <div style="width: auto;">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="资讯最热" name="1"></el-tab-pane>
            <el-tab-pane label="资讯最近" name="2"></el-tab-pane>
          </el-tabs>
        </div>
        <!--<div class="dif alc mrBtm20">
          <div class="fz14 col999 mrRit10 pointer">中国政府网</div>
          <div class="fz14 col999 mrRit10 pointer">人民网</div>
          <div class="fz14 col999 mrRit10 pointer">凤凰网</div>
        </div>-->
        <!--左侧新闻列表-->
        <div
          v-for="(item, index) in left"
          :key="index"
          class="news_item pointer"
          @click="goDetail(item.id)"
          style="margin-bottom: 37px;"
        >
          <!--标题-->
          <div
            class="fs20 col333 tlt one_overflow"
            style="margin-bottom: 14px;width: 560px;"
          >
            {{ item.title }}
          </div>
          <!--图片-->
          <div
            class="dif alc news_img tlt"
            style="margin-bottom: 13px;"
            v-if="item.image"
          >
            <img
              :src="$store.state.url + item2"
              class="one_img"
              v-for="(item2, index2) in getImgArr(item.images)"
              :key="index2"
            />
            <!--<img :src="item.image[1]" class="one_img" >-->
          </div>
          <!--来源 时间-->
          <div class="dif alc">
            <div
              style="font-size: 12px;color:#f00;margin-right: 10px;"
              v-if="item.is_top == 1"
            >
              置顶
            </div>
            <div style="font-size: 12px;color:#999;">{{ item.siteName }}</div>
            <div style="font-size: 12px;color:#999;" class="mrLft20">
              {{ item.createtime }}
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧 热度推荐-->
      <div class="news-right">
        <div class="fs20 col333 mrBtm20 bold tlt dif alc">
          <div class="mrRit30">热度推荐</div>
          <div style="width: 256px;height: 10px;background: #f6f6f6"></div>
        </div>
        <!--排名第一的-->
        <div style="width: 368px;height: 232px;" class="posr pointer">
          <el-carousel height="232px">
            <el-carousel-item v-for="item in swiper" :key="item.id">
              <img :src="item.image" class="w100 h100" alt="" />
            </el-carousel-item>
          </el-carousel>
          <!--蒙版-->
          <!--<div class="mb"></div>-->
          <!--热门标签-->
          <!--<div class="hot">
            <img src="@/assets/image/yizhibang/icon_hot.png" style="width: 12px;height: 14px;">
            <div style="font-size: 12px;" class="colfff mrLft10">NO.1</div>
          </div>-->
          <!--来源 标题-->
          <!--<div class="hot_title">-->
          <!--  <div class="two_overflow fs22 colfff">{{hot[0].title}}</div>-->
          <!--  <div class="colfff" style="font-size: 12px;margin-top: 8px;">{{hot[0].createtime}}  来源：{{hot[0].siteName}}</div>-->
          <!--</div>-->
        </div>
        <!--排名不是第一的-->

     
        <div
          class="box dif alc pointer"
          style="padding: 16px 0;width: 368px;border-bottom: 1px solid #E6E6E6;"
          @click="goDetail(item.id)"
          v-if="index < 4"
          v-for="(item, index) in hot"
          :key="index"
        >
          <div class="posr">
            <div class="hot2">
              <img
                src="@/assets/image/yizhibang/icon_hot.png"
                style="width: 10px;height: 12px;"
              />
              <div style="font-size: 12px;margin-left: 5px;" class="colfff">
                NO.{{ index + 1 }}
              </div>
            </div>
            <img
              class="bgcb"
              style="width: 114px;height: 78px;min-width: 114px;min-height: 78px"
              :src="item.image[1]"
            />
          </div>
          <div class="mrLft20 difcb tlt" style="height: 74px;">
            <div class="fs16 two_overflow" style="color:#1D1E20;">
              {{ item.title }}
            </div>
            <div class="col999" style="font-size: 12px;">
              {{ item.siteName }} {{ item.createtime }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--开屏承诺-->
    <div class="kaiping" v-if="showKaiping">
      <div class="kaiping_cont">
        <!--标题-->
        <div class="kaiping_title">
          {{ title }}
          <div class="close_k" @click="change">
            <img
              src="@/assets/image/yizhibang/00one_close.png"
              class="w100 h100"
            />
          </div>
        </div>
        <div style="overflow-y: auto;height: 400px;">
          <div v-html="content" class="pdTop30"></div>
        </div>
        <!--<div class="fs20 col333 tct bold" style="margin-top: 37px;letter-spacing: 5px">亿指榜官网搜索推荐引擎</div>
        <div class="fs20 col333 tct bold mrTop20" style="letter-spacing: 5px">采用AI技术与全民推荐相结合的方式</div>
        <div class="fs20 col333 tct bold mrTop20" style="letter-spacing: 5px">为您展现不同维度的搜索推荐榜单</div>
        <div class="fs20 col333 tct bold mrTop20" style="letter-spacing: 5px">亿指榜让生活选择更简单！</div>

        <div class="fs14 col666" style="margin-top: 45px;">拒绝完全广告排名 多维选择更靠谱</div>
        <div class="fs14 col666">科技与众推打造最有感情的智能搜索引擎</div>
        <div class="fs14 col666">在繁华星海的服务选择中让您快人一步</div>

        <div class="fs16 bold col333" style="margin-top: 46px;">欢迎您提出宝贵的建议 一起建设我们美好的生活家园</div>
        <div class="fs16 bold col333">为营造安全信赖的服务与应国家要求将实行实名认证注册</div>-->
      </div>
    </div>

    <!--注册登录-->
    <!--<register-center v-if="!$store.state.isLogin && $store.state.isShowLogin"
                     @closeLogin="closeLogin"></register-center>-->

    <!--<share :config="config"></share>-->
  </div>
</template>

<script>
import headers from "@/components/home/head/head.vue"; //头部导航
import industry from "@/components/home/head/industry";
import RegisterCenter from "@/components/home/register/registerCenter";
import ContactCooperation from "@/components/home/head/contactCooperation";
export default {
  // 轮胎采购 用胎无忧
  components: {
    ContactCooperation,
    RegisterCenter,
    headers,
    industry,
  },
  data() {
    return {
      placeholder: "", //搜索占位符
      timeout: "",
      classity: [], //搜索上方分类
      classId: null, //搜索typeid
      isShow: 12, //12 搜索历史  11 行业分类
      content: "", //开屏的郑重承诺内容
      title: "", //开屏的郑重承诺标题
      keyword: "", //搜索关键词
      activeName:"1",//选择的排序
      userSearchHistory: [], //搜索历史
      hot: [], //右侧热度推荐
      search: "亿搜",
      searchList: ["亿搜", "百度", "搜狗", "360", "必应"],
      showSearch: false, //显示搜索引擎选择
      showHistory: false, //显示搜索历史
      msg: "江南无所有、聊赠一枝春",
      url: "",
      left: [], //左侧新闻
      loading: false, //是否正在加载
      page: 1,
      limit: 5,
      hasMore: true,

      token: "",
      showKaiping: false, //设置显示开屏广告

      swiper: [], //轮播图
    };
  },
  mounted() {
    this.classId = 9;
  },
  created() {
    // console.log(this.classId)
    // this.$nextTick(()=>{

    // })
    this.url = this.$store.state.url;
    let token = localStorage.getItem("yizhibang");
    if (token) this.token = token;
    // this.isShowKaiPing() //判断是否需显示开屏提示
    this.getKaiping(); //判断是否需显示开屏提示
    this.getClassify(); //获取搜索上方分类
    this.getuserSearchHistory(token); //获取用户搜索历史
    this.imformation(); //获取右侧热度推荐
    this.leftNews();
    this.rightAdvice();
    this.getConfig();
    this.onScrollBottom();
    this.getSwiper();
  },
  methods: {
    handleClick(tab,e){
      // console.log(tab)
      this.page = 1

      this.leftNews()
    },
    setItem(id) {
      this.classId = id;
    },
    itemIf(item) {
      // console.log(item.id);
      if (item.id == this.classId) {
        return "active";
      }
      return "bb";
    },
    getSwiper() {
      //获取轮播图
      this.$axios("POST", "/api/index/getAd", {}).then((res) => {
        if (res.code == 1) this.swiper = res.data;
      });
    },
    onScrollBottom() {
      console.log(1)
      //滚动到最底部
      let that = this;
      window.onscroll = function() {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if(window.innerWidth>1200){
          if (scrollTop + windowHeight >= scrollHeight) {
            //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
            // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
            if (that.hasMore && !that.loading) {
              that.page++;
              that.leftNews();
            }
          }
        }else{
          if (scrollTop >= windowHeight*that.page) {
            //考虑到滚动的位置一般可能会大于一点可滚动的高度，所以这里不能用等于
            // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
            if (that.hasMore && !that.loading) {
              that.page++;
              that.leftNews();
            }
          }
        }
      };
    },
    isShowKaiPing() {
      //判断是否需要显示开屏广告
      let s = localStorage.getItem("yzbtime");
      let month = new Date().getMonth() + 1;
      let day = new Date().getDate();
      let today = month + "-" + day;
      if (!s || s !== today) {
        this.showKaiping = true;
        localStorage.setItem("yzbtime", today);
      }
    },
    getConfig() {
      //获取配置
      this.$axios("POST", "/api/Index/getConfing", {
        name: "placeholder",
      }).then((res) => {
        if (res.code === 1) this.placeholder = res.data;
      });
    },
    rightAdvice() {
      //获取右侧排行榜
      this.$axios("POST", "/api/site_title/imformation", {
        type: 3,
      }).then((res) => {
        this.hot = res.data.slice(0, 6);
      });
    },
    leftNews() {
      //获取左侧资讯 （点赞榜)
      this.loading = true;
      this.$axios("POST", "/api/search/getInformation", {
        order:this.activeName,
        page: this.page,
        limt: this.limit,
      }).then((res) => {
        this.loading = false;
        if (res.code === 1) {
          if (res.data.length < this.limit) this.hasMore = false;
          if (this.page == 1) {
            this.left = res.data;
          } else {
            this.left = [...this.left, ...res.data];
          }
        }
      });
    },
    hideHistory() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.showHistory = false;
      }, 100);
    },
    goIndustry(i) {
      //显示行业分类
      this.isShow = i;
    },
    goSearch(info) {
      if (this.search === "谷歌") {
        //谷歌搜索
        let url =
          "http://www.google.com.hk/search?hl=zh-CN&source=hp&q=" +
          this.keyword;
        window.open(url, "_blank");
        return;
      }
      if (this.search === "百度") {
        //百度搜索
        let url = "http://www.baidu.com/s?wd=" + this.keyword;
        window.open(url, "_blank");
        return;
      }
      if (this.search === "搜狗") {
        //搜狗搜索
        let url = "https://www.sogou.com/web?query=" + this.keyword;
        window.open(url, "_blank");
        return;
      }
      if (this.search === "360") {
        //360搜索
        let url =
          "https://www.so.com/s?ie=utf-8&fr=none&src=home_none&q=" +
          this.keyword;
        window.open(url, "_blank");
        return;
      }
      if (this.search === "必应") {
        //必应搜索
        let url = "https://cn.bing.com/search?q=" + this.keyword;
        window.open(url, "_blank");
        return;
      }
      this.$refs.searchInput.focus();
      //跳转到搜索页面
      //判断是否登录账号
      let token = localStorage.getItem("yizhibang"); //检测 是否存在token 和 用户信息
      let userinfo = localStorage.getItem("userinfo");
      // if(!token && !userinfo){
      //   this.$alert('请登录后操作',{type: 'error'})
      //       .then(res => {
      //         this.showLogin()
      //       })
      //   return
      // }

      if (info) {
        this.keyword = info;
      }
      if (this.keyword == "") {
        alert("请输入搜索内容");
        return;
      }
      let path = "";
      switch (this.classId) {
        case 1:
          path = "/search"; //官网
          break;
        case 9:
          path = "/search"; //官网
          break;
        case 2:
          path = "/search"; //公司
          break;
        case 3:
          path = "/search/advisory"; //资讯
          break;
        case 4:
          path = "/search/demand"; //需求
          break;
        case 5:
          path = "/search/shopclass"; //店铺
          break;
        case 6:
          path = "/search"; //加盟
          break;
        case 7:
          path = "/search/zhaopin"; //招聘
          break;
        case 8:
          path = "/search/app"; //软件
          break;
      }
      this.$router.push({
        path: path,
        query: {
          keyword: this.keyword,
          type: this.classId,
        },
      });
    },
    getKaiping() {
      //获取开屏广告
      this.$axios("POST", "/api/index/indexContent", {}).then((res) => {
        this.title = res.data[0].title;
        let h = res.data[0].content;
        h = h.replace(/\\/g, "");
        this.content = h;
        this.isShowKaiPing();
      });
    },
    getClassify() {
      //获取搜索上方分类与第一个classid
      this.$axios("POST", "/api/classity/getClassify", {}).then((res) => {
        // console.log(res)
        this.classity = res.data;
        // this.classId = res.data[0].id;
      });
    },
    change() {
      //关闭开屏页面 并且显示登录页面
      // this.$store.commit('showKaiping')
      this.showKaiping = false;
      // this.$store.commit('showLogin',true)
    },
    getuserSearchHistory(token) {
      this.$axios("POST", "/api/member/userSearchHistory", {
        token: token,
      }).then((res) => {
        if (res.code == "1") {
          this.userSearchHistory = res.data;
        } else {
          // alert(res.msg)
        }
      });
    },
    imformation() {
      //获取热度推荐
      this.$axios("POST", "/api/site_title/imformation", {
        type: 3,
      }).then((res) => {
        this.hot = res.data.slice(0, 6);
      });
    },
    goAdvisory(id) {
      //检测是否登录账户
      let token = localStorage.getItem("yizhibang"); //检测 是否存在token 和 用户信息
      let userinfo = localStorage.getItem("userinfo");
      if (!token && !userinfo) {
        this.$alert("请登录后操作", { type: "error" }).then((res) => {
          this.showLogin();
        });
        return;
      }
      //跳转到资讯详情
      this.$router
        .push({
          path: "/search/advisory/detail",
          query: {
            id: id,
          },
        })
        .catch((err) => err);
    },
    showLogin() {
      //打开登录页面
      this.$store.commit("showLogin", true);
      //阻止页面滚动
      let m = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, { passive: false }); //禁止页面滑动
    },
    closeLogin() {
      //关闭登录页面
      this.$store.commit("showLogin", false);
    },
    goDetail(id) {
      //跳转到资讯详情
      this.$router
        .push({
          path: "/search/advisory/detail",
          query: {
            id: id,
            type: 2,
          },
        })
        .catch((err) => err);
    },
  },
  computed: {
    getImgArr() { 
      return function(str) {
        if (!str) return [];
        return str.split(",");
      };
    },
  },
};
</script>

<style scoped>
.kv_more {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 150px;
  z-index: 22;
  width: 65px;
  height: 56px;
  animation: next 3s infinite;
  -webkit-animation: next 3s infinite; /*Safari and Chrome*/
  transform: scale(0.8);
}
@keyframes next {
  0% {
    opacity: 1;
    bottom: 150px;
  }

  100% {
    opacity: 0;
    bottom: 120px;
  }
}

.hot_title {
  padding: 0 22px;
  padding-bottom: 15px;
  box-sizing: border-box;
  position: absolute;
  text-align: left;
  bottom: 0;
  left: 0;
  width: 100%;
}
.hot {
  width: 60px;
  height: 20px;
  background: #eb6100;
  position: absolute;
  display: flex;
  top: 3px;
  left: 3px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.hot2 {
  width: 50px;
  /*height: 20px;*/
  background: #eb6100;
  position: absolute;
  display: flex;
  top: 3px;
  left: 3px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.mb {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.news_img img {
  width: 184px;
  height: 122px;
  background: #cbcbcb;
  margin-right: 4px;
}
.news_img img:first-child {
  border-radius: 10px 0 0 10px;
}
.news_img img:last-child {
  border-radius: 0 10px 10px 0;
  margin-right: 0;
}

.history_item {
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  color: #333333;
}
.history_item:hover {
  color: #366eff;
}
.search_item {
  height: 40px;
  width: 50%;
}
.search_item:hover {
  background-color: #e5eeff;
}
.search_list {
  width: 186px;
  height: 120px;
  border-radius: 0 0 10px 10px;
  position: absolute;
  z-index: 10;
  top: 50px;
  left: -8px;
  background-color: #ffffff;
}
/*开屏承诺*/
.kaiping {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.kaiping_cont {
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 692px;
  height: 480px;
  background: #ffffff;
  border-radius: 20px;
  transform: translate(-50%, -50%);
}
.kaiping_title {
  width: 692px;
  height: 70px;
  background: #3a54ff;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
}
.kaiping_title::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 16px solid #3a54ff;
  border-left: 14px solid rgba(0, 0, 0, 0);
  border-right: 14px solid rgba(0, 0, 0, 0);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
}
.close_k {
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 20px;
  right: 20px;
  border-radius: 50%;
}
.content p {
  background: red;
}
/* 头部布局 */
/* 标题 */
.title {
  margin-top: 80px;
  margin-bottom: 50px;
}
/* 导航 */
.nav {
  width: 500px;
  height: 30px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  color: #004f8a;
  justify-content: space-around;
}
.nav .bb {
  border-bottom: 1px solid #c7c7c7;
}
.nav .active {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.search {
  width: 790px;
  height: 85px;
  margin: 0 auto;
  display: flex;
  position: relative;
  border-radius: 40px;
  background-color: #ffffff;
}
.sea_left {
  margin-left: 11px;
  width: 448px;
  height: 85px;
  line-height: 85px;
  font-size: 24px;
  color: #666;
  box-sizing: border-box;
  padding-right: 15px;
  outline: none;
}
.sea_left::-webkit-input-placeholder {
  color: #999;
}
.sea_left::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
}
.sea_left:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
}
.sea_left:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}
.sea_right {
  width: 150px;
  height: 50px;
  background: linear-gradient(90deg, #3288ff 0%, #3a51ff 100%);
  border-radius: 25px;
  font-size: 23px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frist {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}
.frist > div {
  border: 1px solid rgb(52, 115, 162);
  border-radius: 20px;
  color: rgb(52, 115, 162);
  /* background-color: ; */
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.frist > div:nth-child(1) {
  background-color: rgb(0, 79, 138);
  color: white;
}
/* 历史 */
.history {
  box-sizing: border-box;
  padding: 13px 14px;
  width: 460px;
  height: 170px;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 85px;
  display: flex;
}

/* 新闻 */
.news {
  width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
  justify-content: center;
}
.news_left,
.news-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.n_l_item > img {
  width: 200px;
  height: 120px;
}
.n_r_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.n_r_item {
  width: 50%;
  height: 200px;
  margin-bottom: 20px;
}
.n_r_item > img {
  width: 90%;
  height: 150px;
  margin: 0 auto;
}
.n_r_item_t {
  width: 90%;
  margin: 0 auto;
  text-align: left;
  /* margin-left: ; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /*显示的行数*/
  overflow: hidden;
}

/*隐藏历史滚动条*/
.hidden_scroll::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.hidden_scroll {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.news_img img.one_img {
  border-radius: 10px;
}
.news_item {
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
/*.bg{*/
/*  width: 100%;*/
/*  height: 777px;*/
/*  top: 0;*/
/*  left: 0;*/
/*  background: linear-gradient(0deg, #3288FF 0%, #3A51FF 100%);*/
/*}*/
.waveWrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 740px;
  margin: auto;
}
.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 740px;
  bottom: -1px;
  background: linear-gradient(360deg, #3288ff 0%, #3a51ff 100%);
}
.bgTop {
  z-index: 15;
  opacity: 0.5;
}
.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}
.bgBottom {
  z-index: 5;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-size: 50% 100px;
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.waveMiddle {
  background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-size: 50% 100px;
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}
</style>
