<template>
  <!--搜索界面-->
  <div style="min-height: 95vh">
    <div class="mr0At">
      <div class="search1"></div>

      <div class="search_warp">
        <!--logo-->
        <div class="logo pointer" @click="backIndex()">
          <img src="@/assets/image/yizhibang/16left_logo.png" style="width: 98px;height: 35px;">
        </div>
        <!--密码箱 官网收藏 头像-->
        <div class="head_right2 dif alc fs13 trt posr" style="z-index: 9;">
          <div class="pwdBox posr "><span @click="isShow(1)" class="pointer fs14 colfff">密码箱</span>
            <pass-box v-if="isShow1==1" @closeWarp="closeWarp"></pass-box>
          </div>
          <div class="posr" style="margin-left: 20px;margin-right: 20px;"><span @click="isShow(2)"
              class="pointer fs14 colfff">官网收藏</span>
            <website-collection v-if="isShow1==2" @closeWeb="closeWeb"></website-collection>
          </div>
          <p class="pointer fs14 colfff pointer" style="margin-right: 20px;" @click="showLianxi()">联系反馈/推荐网址</p>

          <div class="user dif alc" v-if="$store.state.isLogin">
            <div class="username dif alc">{{$store.state.userInfo.username}}</div>
            <img class="userIcon" :src="$store.state.userInfo.avatar" alt="" />
            <!--点击头像之后的选项-->
            <div class="header_info tct fs12">
              <div class="pointer posr info_item" v-for="(item,index) in userList" @click="goPage(item)">{{item.name}}
              </div>
              <div class="pointer posr info_item" @click="logout">退出账号</div>
            </div>
          </div>
          <div class="user pointer dif alc" v-if="!$store.state.isLogin"><span @click="showLogin"
              class="fs14 colfff">登录/注册</span></div>
        </div>

        <!--搜索框-->
        <div class="difcc search_input_warp" style="background: none">
          <!-- 导航 -->
          <div class="nav dif alc">
            <div v-for="(item,index) in nav" :key="index" class="pointer fs14 colE0E0E0" @click="isActive=item.id">
              <div :class="isActive==item.id?'active':'bb'" @click="search(item.id)">{{item.name}}</div>
            </div>
          </div>
          <!-- 搜索框 -->
          <div class="search posr dif alc posr">
            <div class="fs24 col3A53FF bold dif alc pdRit30 posr" @click="showSearch=!showSearch"
              style="margin-left: 28px;border-right: 2px solid #00AEFF;">
              <div class="pointer" style="width: 60px;" v-if="search2!=='亿搜'">{{search2}}</div>
              <div class="pointer" style="width: 60px;" v-else>
                <img src="@/assets/image/yizhibang/home_logo.png" style="width: 62px;height: auto">
              </div>
              <i class="el-icon-arrow-down pointer" style="color:#3A53FF;font-weight:bold;margin-left: 15px;"></i>
              <div class="search_list difb difw pdTop20" @mouseleave="showSearch=false" v-if="showSearch">
                <div v-for="(item,index) in searchList" v-if="search2!==item" @click="search2=item"
                  class="pointer search_item difcac">
                  <span v-if="item!=='亿搜'">{{item}}</span>
                  <img v-else src="@/assets/image/yizhibang/home_logo.png" style="width: 62px;height: auto">
                </div>
              </div>
            </div>
            <input ref="searchInput" @keydown.enter="search()" class="sea_left" type="text" v-model="keyword"
              @focus="showHistory=true;getuserSearchHistory();showSearch=false" @blur="hideHistory"
              :placeholder="placeholder" />
            <!--搜索历史和相关搜素-->
            <div class="history shadow_bottom" v-if="showHistory && $store.state.token" style="z-index: 99">
              <!--相关-->

              <!--历史-->
              <div class="w100">
                <div class="colccc fs14 tlt">搜索历史</div>
                <div class="dif difw">
                  <div class="history_item one_overflow pointer trt" style="width: 50%;"
                    @click.stop="goSearch(item.info)" v-for="(item,index) in userSearchHistory" :key="index"
                    v-if="index<15 && item.info!=='undefined' && item.info">{{ item.info }}</div>
                </div>
              </div>
            </div>
            <div class="sea_right pointer" @click="search()">立即搜索</div>
          </div>
        </div>




        <!--搜索框上方选项-->
        <!-- <div class="search_nav difc mr0At mrBtm10" style="width: 1440px;">
           <ul class="difb alc" style="width: 500px;padding-top: 20px;">
             <li v-for="(item,index) in nav" class="fs14 text-red pointer"
                 :class="isActive==item.id?'active':''" @click="search(item.id)">
               {{item.name}}
             </li>
           </ul>
         </div>
         &lt;!&ndash;搜索框 logo 头像&ndash;&gt;
         <div class="search_bar_warp w100 posr" style="z-index: 888;border-bottom: 3px solid #eeeeee;">
           <div class="search_bar w100 difc alc posr">

             &lt;!&ndash;搜索框&ndash;&gt;
             <div class="search_input posr">
               <input type="text" placeholder="吧啦吧啦吧啦" @keydown.enter="search()" v-model="keyword">
               <div class="search_button difcac pointer" @click="search()">
                 <img src="@/assets/image/icon_search.png" style="width: 20px;height: 20px;">
               </div>
             </div>
           </div>
         </div>-->
      </div>


      <!--注册登录-->
      <register-center v-if="!$store.state.isLogin && $store.state.isShowLogin" @closeLogin="closeLogin"
        style="z-index: 99"></register-center>
      <!--合作联系反馈-->
      <contact-cooperation v-if="showCon" @closecon="closecon" style="line-height: 60px;"></contact-cooperation>
    </div>
    <div style="width: 1440px;" class="mr0At">
      <!--显示-->
      <keep-alive :include="$store.state.routerInclude">

        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>
<script>
  import passBox from "@/components/home/head/passBox"; //点击密码箱
  import websiteCollection from "@/components/home/head/websiteCollection";//点击官网收藏
  import registerCenter from "@/components/home/register/registerCenter";
  import ContactCooperation from "@/components/home/head/contactCooperation"; //注册登录界面
  export default {
    components: { ContactCooperation, passBox, websiteCollection, registerCenter },
    name: "Search",
    data() {
      return {
        placeholder: '',
        showCon: false, //显示联系合作反馈
        userSearchHistory: [],//搜索历史
        nav: [ //顶部选项
          { id: 1, name: '官网' },
          { id: 9, name: '网址' },
          { id: 2, name: '公司' },
          { id: 3, name: '资讯' },
          { id: 4, name: '需求' },
          { id: 5, name: '店铺' },
          { id: 6, name: '加盟' },
          { id: 7, name: '招聘' },
          { id: 8, name: '软件' },
        ],
        isActive: 1, //当前选择 选项  搜索类型
        userList: [ //点击头像之后的选项
          { id: 1, cid: 2, name: '会员中心' },
          { id: 1, cid: 1, name: '个人中心' },
          { id: 2, cid: 1, name: '企业中心' },
          { id: 1, cid: 4, name: '我的收藏' },
          { id: 1, cid: 3, name: '我的消息' }
        ],
        change: 12, //11 行业分类,
        isShow1: 0, //密码箱 官网收藏点击显示
        isShow2: false, //登录界面显示
        keyword: '',
        search2: '亿搜',
        searchList: ['亿搜', '百度', '搜狗', '360', '必应'],
        showSearch: false,//显示搜索引擎选择
        showHistory: false,//显示搜索历史
      }
    },
    methods: {
      showLianxi() { //显示联系反馈
        let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
        if (token) {
          this.showCon = true
        } else {
          this.$alert('请登录后操作', { type: 'error' })
            .then(res => {
              this.showLogin()
            })
        }
      },
      closeWeb() { //关闭
        this.isShow1 = 0
      },
      closeWarp() { //关闭密码框
        this.isShow1 = 0
      },
      getConfig() { //获取配置
        this.$axios("POST", "/api/Index/getConfing", { name: 'placeholder' })
          .then(res => {
            if (res.code === 1) this.placeholder = res.data
          })
      },
      goSearch(info) { //点击历史搜索
        let path1 = 0
        let id = this.isActive * 1
        switch (id) {
          case 1: path1 = '/search' //官网
            break;
          case 9: path1 = '/search' //推荐
            break;
          case 2: path1 = '/search' //公司
            break;
          case 3: path1 = '/search/advisory' //资讯
            break;
          case 4: path1 = '/search/demand' //需求
            break;
          case 5: path1 = '/search/shopclass' //店铺
            break;
          case 6: path1 = '/search' //加盟
            break;
          case 7: path1 = '/search/zhaopin' //招聘
            break;
          case 8: path1 = '/search/app' //软件
            break;
        }
        this.keyword = info
        this.$router.push({
          path: path1,
          query: {
            type: this.isActive,
            keyword: info,
            time: new Date().getTime()
          }
        }).catch(err => err)
      },
      closecon() { //关闭联系合作反馈
        this.showCon = false
      },
      backIndex() { //返回首页
        this.$router.push({
          path: '/'
        }).catch(err => err)
      },
      getuserSearchHistory(token) { //获取用户搜索历史
        this.$axios("POST", "/api/member/userSearchHistory", {}).then(res => {
          if (res.code == '1') {
            this.userSearchHistory = res.data
          } else {
            // alert(res.msg)
          }
        })
      },
      hideHistory() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.showHistory = false
        }, 100)
      },
      search(id) { //点击上方的跳转路由和搜索
        console.log(id)
        if (this.search2 === '谷歌') { //谷歌搜索
          let url = 'http://www.google.com.hk/search?hl=zh-CN&source=hp&q=' + this.keyword
          window.open(url, '_blank')
          return
        }
        if (this.search2 === '百度') { //百度搜索
          let url = 'http://www.baidu.com/s?wd=' + this.keyword
          window.open(url, '_blank')
          return
        }
        if (this.search2 === '搜狗') { //搜狗搜索
          let url = 'https://www.sogou.com/web?query=' + this.keyword
          window.open(url, '_blank')
          return
        }
        if (this.search2 === '360') { //360搜索
          let url = 'https://www.so.com/s?ie=utf-8&fr=none&src=home_none&q=' + this.keyword
          window.open(url, '_blank');
          return
        }
        if (this.search2 === '必应') { //必应搜索
          let url = 'https://cn.bing.com/search?q=' + this.keyword
          window.open(url, '_blank');
          return
        }
        if (id) {
          this.isActive = id
        } else {
          id = this.isActive
        }
        id = id * 1
        let path1 = 0
        switch (id) {
          case 1: path1 = '/search' //官网
            break;
          case 9: path1 = '/search' //官网
            break;
          case 2: path1 = '/search' //公司
            break;
          case 3: path1 = '/search/advisory' //资讯
            break;
          case 4: path1 = '/search/demand' //需求
            break;
          case 5: path1 = '/search/shopclass' //店铺
            break;
          case 6: path1 = '/search' //加盟
            break;
          case 7: path1 = '/search/zhaopin' //招聘
            break;
          case 8: path1 = '/search/app' //软件
            break;
        }
        this.$router.push({
          path: path1,
          query: {
            type: this.isActive,
            keyword: this.keyword,
            time: new Date().getTime()
          }
        }).catch(err => err)
      },
      goPage(event) { //跳转到个人中心 企业中心相关页面
        this.$router.push({
          path: '/meCenter',
          query: {
            leftSelect: event.id,
            contentType: event.cid
          }
        }).catch(err => err)
      },
      goIndustry() { //传值给首页 显示行业分类
        this.$emit('goIndustry', this.change)
      },
      isShow(id) { //显示密码箱或者官网收藏
        let ida = ''
        let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
        let userinfo = localStorage.getItem('userinfo')

        if (token && userinfo) {
          ida = this.isShow1
          ida == id ? ida = 0 : ida = id //再次点击切换显示
          this.isShow1 = ida
        } else {
          this.$alert('请登录后操作', { type: 'error' })
            .then(res => {
              this.showLogin()
            })
        }
      },
      showLogin() {
        //打开登录页面
        this.$store.commit('showLogin', true)
        //阻止页面滚动
        let m = function (e) { e.preventDefault(); };
        document.body.style.overflow = 'hidden';
        document.addEventListener("touchmove", m, { passive: false });//禁止页面滑动
      },
      closeLogin(bool) { //关闭登录页面
        this.$store.commit('showLogin', false)
      },
      logout() { //退出登录
        this.$store.commit('del_token')
        this.$router.push('/')
      }
    },
    created() {
      /*this.$http.jsonp('https://sp0.baidu.com/5a1Fazu8AA54nxGko9WTAnF6hhy/su?', {
        params: {
          wd: '卧槽'
        },
        jsonp: 'cb'
      }).then((res) => {
        this.searchRes = res.body.s
      })*/
      let token = localStorage.getItem('yizhibang')
      this.isActive = this.$route.query.type || 1
      this.keyword = this.$route.query.keyword
      this.getuserSearchHistory(token) //获取用户搜索历史
      this.getConfig()

      let route = this.$route
      // 咨询详情
      if (route.path === '/search/advisory/detail') {
        this.isActive = 3
      }
      //需求详情
      if (route.path === '/search/demand/detail') {
        this.isActive = 4
      }
      //软件详情
      if (route.path === '/search/app/detail') {
        this.isActive = 8
      }
    },
    watch: {
      "$route": function (route) {
        console.log('路由变化', route);
        // 咨询详情
        if (route.path === '/search/advisory/detail') {
          this.isActive = 3
        }
      }
    },
  }
</script>
<style scoped>
  .nav {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
  }

  .search_input_warp {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 64px;
  }

  /* 历史 */
  .history {
    box-sizing: border-box;
    padding: 13px 14px;
    width: 460px;
    height: 170px;
    background: #fff;
    border-radius: 0 0 10px 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 85px;
    display: flex;
  }

  .sea_left {
    margin-left: 11px;
    width: 448px;
    height: 85px;
    line-height: 85px;
    font-size: 24px;
    color: #666;
    box-sizing: border-box;
    padding-right: 15px;
    outline: none;
  }

  .sea_left::-webkit-input-placeholder {
    color: #999;
  }

  .sea_left::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999;
  }

  .sea_left:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #999;
  }

  .sea_left:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #999;
  }

  .sea_right {
    width: 150px;
    height: 50px;
    background: linear-gradient(90deg, #3288FF 0%, #3A51FF 100%);
    border-radius: 25px;
    font-size: 23px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search {
    width: 790px;
    height: 85px;
    margin: 0 auto;
    display: flex;
    position: relative;
    border-radius: 40px;
    background-color: #FFFFFF;
  }

  .history_item {
    margin-top: 10px;
    text-align: left;
    font-size: 14px;
    color: #333333;
  }

  .history_item:hover {
    color: #366EFF;
  }

  .search_item {
    height: 40px;
    width: 50%;
  }

  .search_item:hover {
    background-color: #E5EEFF;
  }

  .search_list {
    width: 186px;
    height: 120px;
    border-radius: 0 0 10px 10px;
    position: absolute;
    z-index: 10;
    top: 50px;
    left: -8px;
    background-color: #FFFFFF;
  }

  .nav .active {
    color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
  }

  .search_warp {
    position: relative;
    z-index: 99;
    height: 313px;
    margin: 0 auto;
    width: 1200px;
  }

  .search1 {
    position: absolute;
    width: 100%;
    background: #3765FF;
    height: 313px;
    left: 0;
  }

  .search_nav ul li {
    line-height: 17px;
    padding: 1px 12px;

    border-radius: 8px;
  }

  .search_nav ul li.active {
    border: 1px solid #014F8F;
  }

  .logo {
    position: absolute;
    top: 24px;
    left: 0;
  }

  .search_input input {
    box-sizing: border-box;
    font-size: 18px;
    padding-left: 20px;
    width: 500px;
    height: 50px;
    border: 3px solid #014F8F;
    border-radius: 10px;
  }

  .head_right2 {
    position: absolute;
    top: 24px;
    right: 0;
  }

  .userIcon {}

  .user:hover>.header_info {
    display: block;
  }

  .header_info {
    position: absolute;
    top: 40px;
    left: 43px;
    width: 100px;
    height: 190px;
    padding-top: 5px;
    line-height: 30px;
    z-index: 2;
    display: none;
    background: #FFFFFF;
    border-radius: 6px;
  }

  .header_info::before {
    content: '';
    display: block;
    border-bottom: 21px solid #FFFFFF;
    border-left: 14px solid rgba(0, 0, 0, 0);
    border-right: 14px solid rgba(0, 0, 0, 0);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -5px;
  }

  .info_item {
    color: #999999;
    font-size: 14px;

  }

  .info_item:hover {
    color: #366EFF;
  }
</style>